import { useV3 } from '../experiments/useV3';
import {
  StepType,
  type Step,
} from '@wix/ambassador-online-programs-v3-step/types';

export function useVideoStepData(challengeStep: Step) {
  const isV3 = useV3();
  const stepType = isV3 && challengeStep?.stepType;
  const isVideo = isV3 && stepType === StepType.VIDEO;
  const videoOptions = isVideo && challengeStep?.videoOptions;
  const requiredCompletionPercentage =
    videoOptions?.requiredCompletionPercentage;
  const videoStepDuration = isVideo
    ? challengeStep?.videoOptions.durationInMilliseconds
    : null;

  return {
    isV3,
    stepType,
    isVideo,
    videoOptions,
    requiredCompletionPercentage,
    videoStepDuration,
  };
}
